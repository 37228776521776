import React, { useEffect, useState } from "react"
import MainLayout from "../components/layouts/main-layout.js"
import loadable from "@loadable/component"
import { graphql, Link } from "gatsby"
import HomeHero from "../components/hero/analysis-hero.js"
import pMinDelay from "p-min-delay"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo.js"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Banner from "../components/hero/banner-hometeam.js"

import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"
import { useContext } from "react"

import PartnerSwiper from "../components/swiper/swiper-partner.js"
import VideoSlider from "../components/video-slider/video-slider.js"
import ItConsulting from "../components/analysis-innovation.js"
import Testimonials2 from "../components/swiper/testimonial-swiper.js"
import UnlockEnterprise from "../components/unlock-enterprise.js"
import Cases from "../components/cases.js"
import CaseStudySection from "../components/header/header-casestudy-section.js"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const Analysispage = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    function f2(ev) {
      ev.preventDefault()
    }

    // A function that invokes a callback when the page has unsaved changes.
    // window.addEventListener("beforeunload", f2)

    // A function that invokes a callback when the page's unsaved changes are resolved.
    // window.removeEventListener("beforeunload", f2)

    // document.body.addEventListener("click", f2, {
    //   capture: false,
    //   passive: true,
    // })
    // document.body.addEventListener("touchstart", f2, {
    //   passive: true,
    //   capture: false,
    // })
  }, [])
  const { data } = props

  return (
    <MainLayout location={props.location} dataSrc={data.allSanityCaseStudy}>
      {/*<Hero />*/}
      <Seo
        title="Innovation Analysis | Quandary Consulting Group"
        description="How prepared is your firm for emerging tech and shifting customer demands? See where you stand with our 90-minute innovation analysis (benchmarks included)."
      />

      <HomeHero />
      <PartnerSwiper />
      {/* <VideoSlider /> */}
      <div id="it-consult" className="it-consult-scroll" />
      <ItConsulting />
      <section
        className="faq-wrapper security-audit"
        style={{ padding: "1.5rem 0 4.5rem" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <h2 className="mt-0 text-center">
                Our Innovation Analysis Process
              </h2>
              <Accordion defaultActiveKey="0" key="1">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="1" className="bg-transparent">
                    1. Current State Assessment
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>
                        Identify strengths and gaps by evaluating existing
                        innovation strategies, processes, and outcomes.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="2">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="2" className="bg-transparent">
                    2. Market & Competitive Analysis
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        Pinpoint opportunities and threats based on industry
                        trends, customer needs, and competitor strategies.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="3">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="3" className="bg-transparent">
                    3. Idea Prioritization Framework
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p>
                        Rank and prioritize ideas based on impact, feasibility,
                        and business goals.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="4">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="4" className="bg-transparent">
                    4. Data-Driven Insights
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p>
                        Use analytics and AI to uncover opportunities, reduce
                        risks, and forecast outcomes.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="4">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="4" className="bg-transparent">
                    5. Actionable Roadmap
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p>
                        Deliver a clear, step-by-step plan to implement, track,
                        and scale innovative initiatives.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      {/* <QuandaryValues /> */}
      <Testimonials2 />
      <CaseStudySection />
      <CaseComponent dataSrc={data.allSanityCaseStudy} />
      <Banner />
      <UnlockEnterprise />
      <section
        className="bg-case-study slogan-section"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center">
              <div>
                <h2 className="text-white">
                  Is Your Business Ready to Innovate and Lead the Market?
                </h2>
                {/* <p className="text-white">
                  Schedule your 90-minute Innovation Analysis to uncover your
                  strengths, gaps, and benchmarks for success.
                </p> */}

                <Link
                  to="/contact/"
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                  style={{ marginTop: "auto" }}
                >
                  Schedule Your Analysis Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default Analysispage

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

export const analysisQuery = graphql`
  query analysisQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
